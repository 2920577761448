import { downloadFileByBlob, messageErrors, showWarningConfirm } from './../../../utils/index';

import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import OsTable, { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { PagingMixin } from '@/mixins/paging';
import { ProductionOrderResource } from '@/resource/model/production-management/production-order';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import { ProductionOrderStatusEnum } from '@/resource/enum/production-order-status';
import { productionOrderService, customerService } from '@/api';
import { messageError, translation, debounce } from '@/utils';
import { Message } from 'element-ui';
import { dateFormat } from '@/filters';
@Component({
  name: 'ProductionOrder'
})
export default class ProductionOrder extends mixins(PagingMixin, CustomColumnMixin) {
  /**
   * 表格配置项
   */
  public tableOption: OsTableOption<ProductionOrderResource> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small',
    border: true
  };

  /**
   * 查询条件UI配置
   */
  public queryFormOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'statusList',
      label: 'order.orderStatus',
      option: {
        placeholder: 'order.selectStatus',
        multiple: true
      },
      optionData: productionOrderService.getStatusSelectableList()
    },
    // {
    //   type: 'Select',
    //   field: 'urgentOrderFlag',
    //   label: 'order.urgentOrder',
    //   option: {
    //     placeholder: 'common.select'
    //   },
    //   optionData: (): NormalSelectOptions => {
    //     return [
    //       {
    //         label: translation('common.yes'),
    //         value: 1
    //       },
    //       {
    //         label: translation('common.no'),
    //         value: 0
    //       }
    //     ];
    //   }
    // },
    {
      type: 'Select',
      field: 'customerId',
      label: 'order.customer',
      option: {
        placeholder: 'order.selectCustomer',
        filterable: true
      },
      optionData: []
    },
    {
      type: 'Select',
      field: 'supplierIdList',
      label: 'order.supplierName',
      option: {
        placeholder: 'order.selectSupplier',
        filterable: true,
        multiple: true
      },
      optionData: []
    },
    {
      type: 'Input',
      field: 'createUserName',
      label: 'common.createUserName',
      option: {
        placeholder: 'common.inputCreateUserName'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'createDate',
      label: 'order.createDate',
      option: {
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();

            return callbackDateTime >= currentDateTime;
          }
        }
      }
    },
    {
      type: 'Input',
      field: 'itemCode',
      label: 'orderProduct.itemCode',
      option: {
        placeholder: 'consumed.inputItemCode'
      }
    }
  ];

  /**
   * 查询表单对象
   */
  public queryForm: Partial<{
    keywords: string;
    statusList: Array<number>;
    customerId: number | null;
    supplierIdList: Array<Number>;
    createUserName: string;
    createDate: Array<string>;
    startDate: string | null;
    endDate: string | null;
    idList: Array<number> | null;
    itemCode: String;
  }> = {
    keywords: '',
    statusList: [],
    customerId: null,
    supplierIdList: [],
    createDate: [],
    createUserName: '',
    startDate: null,
    endDate: null,
    idList: null,
    itemCode: ''
  };

  /**
   * 表格工具栏配置
   */
  public tableToolbarOptions: Array<OperationOption> = [
    {
      operationType: 'batchDistribute',
      slot: 'start',
      label: 'order.batchDistribute',
      type: 'primary',
      icon: 'el-icon-s-promotion',
      disabled: true,
      permissionCode: 'production:order:batchPlaceOrder',
      handleClick: (): void => {
        this.batchDistributeOrder();
      }
    },
    {
      operationType: 'batchCancel',
      slot: 'start',
      label: 'button.batchCancel',
      icon: 'el-icon-close',
      plain: true,
      disabled: true,
      permissionCode: 'production:order:batchCancel',
      handleClick: (): void => {
        this.batchCancel();
      }
    },
    {
      operationType: 'batchDelete',
      slot: 'start',
      label: 'button.batchDelete',
      icon: 'el-icon-delete',
      plain: true,
      disabled: true,
      permissionCode: 'production:order:batchDelete',
      handleClick: (): void => {
        this.batchDelete();
      }
    },
    {
      operationType: 'exportDetail',
      slot: 'start',
      label: 'order.exportDetail',
      icon: 'el-icon-download',
      permissionCode: 'production:order:exportPurchaseDetail',
      handleClick: (): void => {
        this.exportDetail();
      }
    }
  ];

  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<ProductionOrderResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'code',
      label: 'order.code',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'projectName',
      label: 'order.projectName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerName',
      label: 'order.customerName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'supplierName',
      label: 'order.supplierName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'area',
      label: 'order.area',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'progress',
      label: 'order.progress',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '140px',
      showOverflowTooltip: false
    },
    {
      prop: 'createUserName',
      label: 'order.createUserName',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'order.createTime',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  /**
   * 表格行操作配置
   */
  public rowOperationOption: RowOperation<ProductionOrderResource> = {
    fixed: 'right',
    width: '180px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        size: 'small',
        permissionCode: 'production:order:edit',
        handleClick: (row: ProductionOrderResource): void => {
          // 去编辑订单
          this.goEdit(row);
        },
        dynamicHidden: (rowData: ProductionOrderResource): boolean => {
          return Number(rowData.status) !== ProductionOrderStatusEnum.new;
        }
      },
      {
        operationType: 'distribute',
        type: 'text',
        label: 'button.distribute',
        icon: 'el-icon-s-promotion',
        size: 'small',
        permissionCode: 'production:order:placeOrder',
        handleClick: (row: ProductionOrderResource): void => {
          // 派单操作
          this.distributeOrder(row.id);
        },
        dynamicHidden: (rowData: ProductionOrderResource): boolean => {
          return Number(rowData.status) !== ProductionOrderStatusEnum.new;
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        size: 'small',
        permissionCode: 'production:order:delete',
        handleClick: (row: ProductionOrderResource): void => {
          this.delete(row.id);
        },
        dynamicHidden: (rowData: ProductionOrderResource): boolean => {
          return Number(rowData.status) !== ProductionOrderStatusEnum.new;
        }
      },
      {
        operationType: 'finish',
        type: 'text',
        label: 'processAction.finish',
        icon: 'el-icon-check',
        size: 'small',
        permissionCode: 'production:order:onFinish',
        handleClick: (row: ProductionOrderResource): void => {
          this.onFinish(row.id);
        },
        dynamicHidden: (rowData: ProductionOrderResource): boolean => {
          return Number(rowData.status) !== ProductionOrderStatusEnum.waitingAccept;
        }
      },
      {
        operationType: 'change',
        type: 'text',
        label: 'button.change',
        icon: 'el-icon-refresh',
        size: 'small',
        permissionCode: 'production:order:change',
        handleClick: (row: ProductionOrderResource): void => {
          this.change(row.id);
        },
        dynamicHidden: (rowData: ProductionOrderResource): boolean => {
          return (
            Number(rowData.status) !== ProductionOrderStatusEnum.processing &&
            Number(rowData.type) !== ProductionOrderStatusEnum.processing
          );
        }
      },
      {
        operationType: 'cancel',
        type: 'text',
        label: 'button.cancel',
        icon: 'el-icon-close',
        size: 'small',
        permissionCode: 'production:order:cancel',
        handleClick: (row: ProductionOrderResource): void => {
          this.cancel(row.id);
        },
        dynamicHidden: (rowData: ProductionOrderResource): boolean => {
          return Number(rowData.status) !== ProductionOrderStatusEnum.waitingAccept;
        }
      },
      {
        operationType: 'confirm',
        type: 'text',
        label: 'button.confirmComplete',
        icon: 'el-icon-finished',
        size: 'small',
        permissionCode: 'production:order:finish',
        handleClick: (row: ProductionOrderResource): void => {
          this.confirmComplete(row.id);
        },
        dynamicHidden: (rowData: ProductionOrderResource): boolean => {
          return Number(rowData.status) !== ProductionOrderStatusEnum.waitingConfirm;
        }
      }
    ]
  };

  /**
   * 选中数据缓存对象
   */
  private selectedRows: Array<ProductionOrderResource> = [];

  public activated(): void {
    if (this.$route.query.itemCode) {
      // 路由携带查询条件参数，就路由的为主
      Object.assign(this.queryForm, {
        itemCode: (this.$route.query.itemCode as string) || ''
      });
      this.$router.push({ query: {} });
    }
    this.reloadData();
  }

  public created(): void {
    this.initCustomSelectableList();
    this.initSupplierSelectableList();
    this.initColumns(this.tableColumnOptions, 'productionOrder');
    this.reloadData();
  }

  /**
   * 去订单详情
   * @param rowData 行数据
   */
  public goDetail(rowData: ProductionOrderResource): void {
    this.$router.push({
      path: '/production-order-detail',
      query: {
        orderId: rowData.id.toString()
      }
    });
  }

  /**
   * 检索按钮
   */
  public handleQueryClick(): void {
    this.loadData();
  }

  /**
   * 重新加载数据
   */
  @debounce()
  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.tableRef as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getStatusI18Key(status: ProductionOrderStatusEnum): string {
    return productionOrderService.getStatusI18Key(status);
  }

  /**
   * 根据状态获取class
   * @param status  状态值
   * @returns
   */
  public getStatusClass(status: ProductionOrderStatusEnum): string {
    return productionOrderService.getStatusClass(status);
  }

  /**
   * 分页切换回调
   */
  public pagingData(): void {
    this.loadData();
  }

  /**
   * 表格行选中事件
   */
  public handleSelectionChange(selectedData: Array<ProductionOrderResource>): void {
    this.selectedRows = selectedData;
  }

  /**
   * 表格行选中数据监听
   */
  @Watch('selectedRows')
  public handleSelectionChanged(value: Array<ProductionOrderResource>): void {
    const allowOperations = ['batchDistribute', 'batchDelete', 'batchCancel'];
    this.tableToolbarOptions.forEach(x => {
      if (allowOperations.indexOf(x.operationType) > -1) {
        x.disabled = value.length === 0;
      }
    });
  }

  @Watch('queryForm.createDate')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.startDate = undefined;
      this.queryForm.endDate = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.startDate = dateFormat(value[0]);
      this.queryForm.endDate = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  /**
   * 数据加载
   */
  private loadData(): void {
    this.tableOption.loading = true;
    productionOrderService
      .getList(this.queryForm as ProductionOrderResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  /**
   * 初始化当前用户可选客户列表
   */
  private async initCustomSelectableList(): Promise<void> {
    const list = await customerService.getAllUsingCustomer();

    const selectableList: Array<any> = [];
    list.forEach(item => {
      selectableList.push({
        label: item.companyName,
        value: item.id
      });
    });

    const option = this.queryFormOption.find(item => item.field === 'customerId');

    option!.optionData = selectableList;
  }

  /**
   * 初始化当前用户可选供应商列表
   */
  private async initSupplierSelectableList(): Promise<void> {
    const list = await productionOrderService.getAllUsingSupplier();

    const selectableList: Array<any> = [];
    list.forEach(item => {
      selectableList.push({
        label: item.supplierName,
        value: item.supplierId
      });
    });

    const option = this.queryFormOption.find(item => item.field === 'supplierIdList');

    option!.optionData = selectableList;
  }

  /**
   * 派发生产订单
   * @param id 订单ID
   */
  private distributeOrder(id: number): void {
    showWarningConfirm(translation('order.confirmDistribute'))
      .then(async () => {
        try {
          await productionOrderService.distribute(id);
          this.reloadData();
          Message.success(translation('order.distributeSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('order.distributeCancel'));
      });
  }

  /**
   * 批量派发生产订单
   */
  private batchDistributeOrder(): void {
    const availableRows = this.selectedRows.filter(row => Number(row.status) === ProductionOrderStatusEnum.new);

    if (availableRows.length <= 0) {
      Message.warning(translation('order.selectNewOrderTip'));
      return;
    }

    showWarningConfirm(translation('order.confirmDistribute'))
      .then(async () => {
        try {
          const idList: Array<number> = availableRows.map(row => row.id);
          await productionOrderService.batchDistribute(idList);
          this.reloadData();
          Message.success(translation('order.distributeSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('order.distributeCancel'));
      });
  }

  /**
   * 批量删除
   */
  private batchDelete(): void {
    const availableRows = this.selectedRows.filter(row => Number(row.status) === ProductionOrderStatusEnum.new);

    if (availableRows.length <= 0) {
      Message.warning(translation('order.selectNewOrderTip'));
      return;
    }

    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          const idList: Array<number> = availableRows.map(row => row.id);
          await productionOrderService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  /**
   * 批量取消
   */
  private batchCancel(): void {
    const availableRows = this.selectedRows.filter(
      row => Number(row.status) === ProductionOrderStatusEnum.waitingAccept
    );

    if (availableRows.length <= 0) {
      Message.warning(translation('order.selectWaitingAccpetOrderTip'));
      return;
    }

    showWarningConfirm(translation('order.confirmCancel'))
      .then(async () => {
        try {
          const idList: Array<number> = availableRows.map(row => row.id);
          await productionOrderService.batchCancel(idList);
          this.reloadData();
          Message.success(translation('order.cancelSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  /**
   * 删除
   * @param id 订单ID
   */
  private delete(id: number): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await productionOrderService.delete(id);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }
  /**
   * 完成
   * @param id 订单ID
   */
  private onFinish(id: number): void {
    showWarningConfirm(translation('order.confirmFinish'))
      .then(async () => {
        try {
          await productionOrderService.onFinish(id);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageErrors(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }
  /**
   * 变更
   * @param id 订单ID
   */
  private change(id: number): void {
    /**
     * 去新建变更单页面
     */
    this.$router.push({
      path: '/add-production-order-change',
      query: {
        orderId: id.toString()
      }
    });
  }

  /**
   * 取消订单
   * @param id 订单ID
   */
  private cancel(id: number): void {
    showWarningConfirm(translation('order.confirmCancel'))
      .then(async () => {
        try {
          await productionOrderService.cancel(id);
          this.reloadData();
          Message.success(translation('order.cancelSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  /**
   * 确认完成
   */
  private confirmComplete(id: number): void {
    showWarningConfirm(translation('order.confirmComplete'))
      .then(async () => {
        try {
          await productionOrderService.confirmComplete(id);
          this.reloadData();
          Message.success(translation('order.orderCompleted'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }
  /**
   * 去编辑
   * @param rowData 编辑订单对象
   */
  private goEdit(rowData: ProductionOrderResource): void {
    this.$router.push({
      path: '/add-production-order',
      query: {
        id: rowData.id.toString()
      }
    });
  }

  /**
   * 导出生产订单明细数据
   */
  private async exportDetail(): Promise<void> {
    this.tableOption.loading = true;
    const idList = this.selectedRows.map(row => row.id!);

    try {
      const blob = await productionOrderService.exportDetail(this.queryForm, idList);
      downloadFileByBlob(`${translation('order.orderDetail')}_${dateFormat(new Date())}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }
}
